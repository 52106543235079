import { Button, TablePaginationConfig } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../hooks/global.structures';
import {
  api,
  defaultAPIErrorHandler,
  IPaginatedList,
} from '../../../services/api';
import UsersTable from './table';
import { UserAddOutlined } from '@ant-design/icons';

const Users = () => {
  const [filters, setFilters] = React.useState({
    page: 0,
    deleted: true,
  });
  const [items, setItems] = React.useState<IUser[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  React.useEffect(() => {
    setLoading(true);
    setItems([]);
    api
      .get<IPaginatedList<IUser>>('/users', {
        params: filters,
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, onChangePagination]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 style={{ marginRight: 10 }}>Usuários</h1>
        <Button
          icon={<UserAddOutlined />}
          onClick={() => navigate('/admin/users/create')}
        >
          Novo Usuário
        </Button>
      </div>

      <UsersTable
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
      />
    </div>
  );
};

export default Users;
