import { notification } from 'antd';
import axios from 'axios';
import { IUser } from '../hooks/global.structures';
import { authApiInterceptor } from '../interceptors/authApi.interceptor';

//#region API
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => authApiInterceptor(api, config));

export const apiPartner = axios.create({
  baseURL: process.env.REACT_APP_API_PARTNER_URL,
});

apiPartner.interceptors.request.use((config) => authApiInterceptor(apiPartner, config));

export const marketingApi = axios.create({
  baseURL: process.env.REACT_APP_MARKETING_API_URL,
});

marketingApi.interceptors.request.use((config) => authApiInterceptor(marketingApi, config));

export const setApiToken = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};
//#endregion


//#region ENUMS
export enum NotificationEntity {
  REPORT = 'REPORT',
}

export enum NotificationStatus {
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum Action {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum Entity {
  USER = 'USER',
  MARKETING_BANNER = 'MARKETING_BANNER',
  REPORT = 'REPORT',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  PARTNER = 'PARTNER',
}

export enum ErrorIdentifier {
  INTERNAL_SERVER_ERROR = 'INT.001',
  VALIDATION_ERROR = 'VAL.001',
  USER_NOT_FOUND = 'USR.001',
  USER_ALREADY_EXISTS = 'USR.002',
  USER_INVALID_CREDENTIALS = 'USR.003',
  USER_NOT_AUTHORIZED = 'USR.004',
  INVALID_TOKEN = 'AUTH.001',
  BANNER_NOT_FOUND = 'BAN.001',
  REPORT_NOT_FOUND = 'REP.001',
  REPORT_ALREADY_BEING_PROCESSED = 'REP.002',
  NOTIFICATION_NOT_FOUND = 'NOT.001',
}

export enum ReportType {
  SYSDUCASH_COMPANIES = 'SYSDUCASH_COMPANIES',
  SYSDUCASH_STORES = 'SYSDUCASH_STORES',
  WALLETAPI_NEW_USERS = 'WALLETAPI_NEW_USERS',
  SYSDUCASH_VALIDATION = 'SYSDUCASH_VALIDATION',
}

export enum ReportStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum CampaignType {
  PUSH = 'PUSH',
}

export enum CampaignStatus {
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}
export enum CampaignVariable {
  AGE = 'AGE',
  NAME = 'NAME',
  CPF = 'CPF',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum CampaignVariableOperators {
  '=' = '=',
  '<' = '<',
  '>' = '>',
  '<=' = '<=',
  '>=' = '>=',
  'CONTAINS' = 'CONTAINS',
  'BETWEEN' = 'BETWEEN',
}

export enum PlanAccessEnum {
  BANNERS = 'BANNERS',
  DISCOUNTS_CARD = 'DISCOUNTS_CARD',
  VOUCHERS = 'VOUCHERS',
  HEALTH = 'HEALTH',
  MAIN_STORES = 'MAIN_STORES',
  PARTNERS = 'PARTNERS',
}

export enum WhitelistFileStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum InsertStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}
//#endregion


//#region INTERFACES
interface IGetContractorsOptions {
  forceRefresh?: boolean;
}

export interface ILoginRequestBody {
  email: string;
  password: string;
}

export interface ILoginResponseBody {
  access_token: string;
  token_type: string;
  expires_in: number;
  user: IUser;
}

export interface INotification {
  id: string;
  userId: string;
  entity: NotificationEntity;
  entityId?: string;
  status: NotificationStatus;
  message?: string;
  read: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IActionHistory {
  id: string;
  user: IUser;
  action: string;
  entity: string;
  entityId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IReport {
  id: string;
  type: ReportType;
  fileUrl?: string;
  status: ReportStatus;
  statusMessage?: string;
  user?: IUser;
  userId?: string;
  createdAt: Date;
  updatedAt: Date;
}


export interface IAppUsers {
  id: string;
  corporate: number;
  fileUrl?: string;
  errorsFileUrl?: string;
  status: InsertStatus;
  statusMessage?: string;
  user?: IUser;
  userId?: string;
  createdAt: Date;
  updatedAt: Date;
}

/**
 * Interface básica de dados de lista paginada
 */
export interface IPaginatedList<T> {
  page: number;
  itemsPerPage: number;
  totalItems: number;
  items: T[];
  errors?: T[];
}

/**
 * Interface de dados de lista paginada Protheus/microsserviço
 */
export interface IPaginatedListProtheus<T> {
  page: number;
  pageSize: number;
  total: number;
  [key: string]: T[] | number;
}

export interface IFindManyActionsHistoryDTO {
  page?: number;
  itemsPerPage?: number;
  userName?: string;
  userId?: string;
  startDate?: string;
  endDate?: string;
  changes?: string;
  action?: Action;
  entity?: Entity;
  entityId?: string;
}

/**
 * @deprecated Interface de dados de empresa Sysducash (descontinuado)
 */
export interface ICompany {
  id?: number;
  cnpj?: string;
  razao_social?: string;
  nome_fantasia?: string;
  inscricao_municipal?: string;
  atividade_economica?: string;
  segmento_id?: number;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  municipio_id?: number;
  uf_id?: number;
  nome_gestor?: string;
  telefone_gestor?: string;
  email_gestor?: string;
  cargo_gestor?: string;
  super_economia?: boolean;
  rede_desconto?: boolean;
  classificacao_id?: number;
  logo?: string;
  rd_site?: string;
  rd_logo?: string;
  rd_regras?: string;
  analista_id?: number;
  created_by?: number;
  updated_by?: number;
  created_at?: string;
  updated_at?: string;
  lat?: number;
  lng?: number;
  politica_desconto?: string;
  status?: boolean;
  pague_com_ducash?: boolean;
  sigla?: string;
  municipio?: string;
}

/**
 * Interface de dados de empresa Protheus
 */
export interface ICompanyProtheus {
  codigo?: string;
  codigoLoja?: string;
  ativo?: boolean;
  cnpj?: string;
  nome?: string;
  razaoSocial?: string;
  municipio?: string;
  codigoMunicipio?: string;
  uf?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cep?: string;
  nomeGestor?: string;
  emailGestor?: string;
  telefoneGestor?: string;
  politicaDesconto?: string;
  dataCriacaoSysducash?: string;
  dataCriacaoProtheus?: string;
  latitude?: string;
  longitude?: string;
  analista?: string;
  spread?: string;
  porcentagemSpread?: string;
};

/**
 * @deprecated Interface de dados de loja Sysducash (descontinuado)
 */
export interface IStore {
  id?: number;
  bairro?: string;
  cep?: string;
  complemento?: string;
  empresa_id?: number;
  logradouro?: string;
  municipio_id: number;
  cidade?: {
    nome?: string;
  };
  nome?: string;
  numero?: string;
  uf_id: number;
  uf?: {
    nome?: string;
    sigla?: string;
  };
  empresa?: {
    nome_fantasia?: string;
  };
}

/**
 * Interface de dados de loja Protheus
 */
export interface IStoreProtheus {
  codigo?: string;
  ativo?: boolean;
  idEmpresa?: string;
  cnpj?: string;
  razaoSocial?: string;
  nome?: string;
  filial?: string;
  unidade?: string;
  cep?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  municipio?: string;
  uf?: string;
  nomeGestor?: string;
  telefoneGestor?: string;
  emailGestor?: string;
  politicaDesconto?: string;
  categorias?: Array<string>;
  dataCriacaoSysducash?: string;
  dataCriacaoProtheus?: string;
  latitude?: string;
  longitude?: string;
  logotipo?: string;
  contatos?: Array<Contact>;
  analista?: string;
}

/**
 * Interface de dados específicos de GET de loja Protheus
 */
export interface IGetStoreProtheus extends IStoreProtheus {
  email?: string;
  telefone?: string;
  codigosTaxonomias?: string[];
}

interface Contact {
  nome: string;
  email: string;
  telefone: string;
};

/**
 * Interface de dados de categoria de loja Protheus
 */
export interface ICategoryProtheus {
  codigo: string;
	descricao: string;
	codigoCategoriaSuperior: string;
	ativo: boolean;
}

export type CampaignVariableInputType = {
  variable: CampaignVariable;
  operator: CampaignVariableOperators;
  id?: number;
} & (
  | {
      range: number[] | string[];
      value?: never;
    }
  | {
      range?: never;
      value: string | number;
    }
);

export interface ICampaign {
  id: string;
  type: CampaignType;
  title: string;
  content?: string;
  audiences?: CampaignVariableInputType[];
  executionDate?: Date;
  status: CampaignStatus;
  estimatedRecipients?: number;
  data?: Record<string, string>;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface IContractor {
  id: string;
  name: string;
}

export interface IPlan {
  id: string;
  name: string;
  access: PlanAccessEnum[];
}

export interface IApiContractor {
  id: string;
  cnpj: string;
  publicName: string;
  tradeName: string;
  active: boolean;
  logo?: string;
  plan: IPlan;
  theme: {
    primaryColor: string;
    secondaryColor: string;
  };
  contractStartDate: Date;
  contractEndDate: Date;
  migration: {
    walletApiId: number;
  };
  whitelistSource: string;
}

export interface IApiAccess {
  id: string;
  active: boolean;
  partnerName: string;
  token: string;
  createdAt: string;
  updatedAt: string;
}

export interface IFindManyApiAccessDTO {
  page?: number;
  itemsPerPage?: number;
}


export interface IWhitelistFile {
  id: string;
  fileUrl?: string;
  contractorId: string;
  status: WhitelistFileStatus;
  statusMessage?: string;
  userId?: string;
  user?: IUser;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICreateWhitelistRegister {
  cpf: string;
  nomevida?: string;
  email?: string;
  matricula?: string;
  sexo?: string;
  status: boolean;
  bairro?: string;
  cidade?: string;
  uf?: string;
  telefone?: string;
  telefone2?: string;
  dt_insert?: string;
  dt_update?: string;
  datanascimento?: string;
}

export interface IWhitelistResponse {
  allowed: boolean;
  sources?: string[];
}
//#endregion


//#region METHODS
let contractors: IApiContractor[] = [];

export const getContractors = async (options?: IGetContractorsOptions) => {
  if (contractors?.length && !options?.forceRefresh) {
    return contractors;
  }

  try {
    const response = await api.get('/contractors-api?usePagination=false');
    contractors = response.data?.items || [];
    return response.data?.items;
  } catch (error) {
    return defaultAPIErrorHandler(error);
  }
};


const errorMessages: { [key in ErrorIdentifier]?: string } = {
  [ErrorIdentifier.REPORT_ALREADY_BEING_PROCESSED]:
    'Já existe um relatório desse tipo sendo processado.',
};

export const defaultAPIErrorHandler = (error: any) => {
  let errorMessage = 'Ocorreu um erro inesperado';
  if (error?.isAxiosError && error.response?.data?.message) {
    errorMessage = error.response.data.message;

    const customErrorMessage =
      errorMessages[error.response.data.error as ErrorIdentifier];
    if (error.response.data.error && customErrorMessage) {
      errorMessage = customErrorMessage;
    }
  }

  if (error?.response?.status === 401) {
    window.location.href = '/login';
  }

  notification.error({
    message: 'Erro',
    description: errorMessage,
  });
};
//#endregion
