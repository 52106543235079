import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, InputNumber, Radio, Select, Space, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { ICity, IState, apiIbge } from "../../../../services/ibge";
import { ICompanyProtheus, apiPartner, defaultAPIErrorHandler } from "../../../../services/api";
import BackButton from "../../../../components/back-button";

const { TextArea } = Input;

const EditCompany = () => {
    const [loading, setLoading] = React.useState(false);

    const [active, setActive] = React.useState<boolean>();
    const [spread, setSpread] = React.useState<string>();

    const [states, setStates] = React.useState<IState[]>([])
    const [cities, setCities] = React.useState<ICity[]>([])
    const [selectedState, setSelectedState] = React.useState<{ value: number, label: string }>()
    const [selectedCity, setSelectedCity] = React.useState<{ value: string, label: string }>()

    const [form] = Form.useForm()

    const navigate = useNavigate();
    const location = useLocation();
    const company = location.state as ICompanyProtheus;

    const getStates = async () => {
        apiIbge
            .get<IState[]>(`/states`)
            .then((response) => {
                setStates(response.data as IState[]);
                const selectState = response.data.map((state) => {
                    return {
                        value: state.id,
                        label: state.acronym,
                    }
                }).find(state => state.label === company.uf);
                setSelectedState(selectState);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));
    }

    const getCities = (stateId: number) => {
        apiIbge
            .get<ICity[]>(`/cities`, { params: { stateId: stateId } })
            .then((response) => {
                setCities(response.data as ICity[]);
                const selectCity = response.data.map((city) => {
                    return {
                        value: city.id,
                        label: city.name.toUpperCase(),
                    }
                }).find(city => city.value.toString() === company.codigoMunicipio);
                setSelectedCity(selectCity);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));

        return;
    };

    const citiesFilter = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toUpperCase().includes(input.toUpperCase());

    const handleSubmit = React.useCallback(async (data: {
        cnpj: string,
        companyName: string,
        tradingName: string,
        postalCode: string,
        street: string,
        neighbourhood: string,
        streetNumber: string,
        streetComplement: string,
        analist: string,
        spreadPercent: string,
        discountPolicy: string,
    }) => {
        setLoading(true);

        if (!selectedState || !selectedCity) {
            notification.warning({
                message: "Aviso",
                description: "Informe o Estado e Cidade",
            });
            setLoading(false);

            return;
        }

        if (!spread) {
            notification.warning({
                message: "Aviso",
                description: "Informe se há Spread",
            });
            setLoading(false);

            return;
        }

        try {
            await apiPartner.put(`/company`, {
                ativo: active,
                codigo: company.codigo,
                codigoLoja: company.codigoLoja,
                cnpj: data.cnpj,
                razaoSocial: data.companyName,
                nome: data.tradingName,
                cep: data.postalCode,
                logradouro: data.street,
                bairro: data.neighbourhood,
                numero: data.streetNumber,
                complemento: data.streetComplement,
                uf: selectedState.label,
                municipio: selectedCity.label,
                codigoMunicipio: selectedCity.value.toString(),
                analista: data.analist,
                spread: spread,
                porcentagemSpread: data.spreadPercent.toString(),
                politicaDesconto: data.discountPolicy,
            });

            notification.success({
                message: "Empresa editada com sucesso!",
            });
        } catch (error) {
            defaultAPIErrorHandler(error);
        } finally {
            setLoading(false);
        }
    }, [company, active, spread, selectedState, selectedCity]);

    React.useEffect(() => {
        if (!company) {
            notification.warning({
                message: 'Aviso',
                description: 'Empresa não encontrada',
            });

            return;
        }

        setActive(company.ativo)
        setSpread(company.spread)
        getStates();
    }, [])

    React.useEffect(() => {
        if (selectedState) {
            form.setFieldValue('state', selectedState)
            getCities(selectedState.value);
        }
    }, [selectedState])

    React.useEffect(() => {
        form.setFieldValue('city', selectedCity)
    }, [selectedCity])


    return (
        !company ? <Navigate to="/admin/companies" />
            :
            <div>
                <BackButton
                    title={`Administração > Empresas > ${company.nome} > Editar`}
                    pathToNavigate="/admin/companies"
                    showConfirmation
                />
                <h1 style={{ marginTop: 32, marginBottom: 32 }}>{'Editar Empresa'}</h1>
                <Form
                    form={form}
                    name="form-company-edit"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                    initialValues={{
                        cnpj: company.cnpj,
                        companyName: company.razaoSocial,
                        tradingName: company.nome,
                        postalCode: company.cep,
                        street: company.logradouro,
                        neighbourhood: company.bairro,
                        streetNumber: company.numero,
                        streetComplement: company.complemento,
                        state: company.uf,
                        city: company.municipio,
                        analist: company.analista,
                        spread: company.spread,
                        spreadPercent: company.porcentagemSpread,
                        discountPolicy: company.politicaDesconto,
                    }}
                    layout="horizontal"
                    labelAlign="left"
                    onFinish={handleSubmit}
                    style={{ maxWidth: 600, minWidth: 100, margin: 20 }}
                >
                    <Form.Item label="Ativo" name="active">
                        <Checkbox checked={active} onChange={() => setActive(!active)} />
                    </Form.Item>
                    <Form.Item label="CNPJ" name="cnpj">
                        <Input required minLength={14} />
                    </Form.Item>
                    <Form.Item label="Razão Social" name="companyName">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Nome Fantasia" name="tradingName">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Analista" name="analist">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Spread" name="spread">
                        <Radio.Group onChange={(value) => setSpread(value.target.value)}>
                            <Radio value={'Sim'}>Sim</Radio>
                            <Radio value={'Nao'}>Não</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Porcentagem de Spread" name="spreadPercent">
                        <InputNumber
                            type={'number'}
                            min={0}
                            max={100}
                            pattern={'[1-9]'}
                            addonAfter={'%'}
                        />
                    </Form.Item>
                    <Form.Item label="Política de Desconto" name="discountPolicy">
                        <TextArea required rows={4} />
                    </Form.Item>

                    <h3>Endereço</h3>
                    <div className="address-container">
                        <Form.Item label="CEP" name="postalCode">
                            <Input required />
                        </Form.Item>
                        <Form.Item label="Logradouro" name="street">
                            <Input required />
                        </Form.Item>
                        <Form.Item label="Número" name="streetNumber">
                            <Input required />
                        </Form.Item>
                        <Form.Item label="Complemento" name="streetComplement">
                            <Input placeholder={'Opcional'} />
                        </Form.Item>
                        <Form.Item label="Bairro" name="neighbourhood">
                            <Input required />
                        </Form.Item>
                        <Form.Item label="Estado" name="state">
                            <Select
                                labelInValue
                                placeholder="Selecione um Estado"
                                options={
                                    states.map((state) => {
                                        return {
                                            value: state.id,
                                            label: state.acronym,
                                        };
                                    })
                                }
                                onChange={(value: { value: number, label: string }) => {
                                    setSelectedState(value);
                                    setSelectedCity(undefined);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Município" name="city">
                            <Select
                                showSearch
                                labelInValue
                                placeholder="Selecione ou pesquise uma cidade"
                                options={
                                    cities.map((city) => {
                                        return {
                                            value: city.id,
                                            label: city.name.toUpperCase(),
                                        }
                                    })
                                }
                                filterOption={citiesFilter}
                                onChange={(value) => setSelectedCity(value)}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Space size={"large"}>
                            <Button type={'primary'} htmlType={'submit'}>
                                {loading ? <LoadingOutlined /> : "Salvar"}
                            </Button>
                            <Button onClick={() => navigate(`/admin/companies/${company.cnpj}/stores`, { state: company })}>
                                {'Ver Lojas'}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
    );
}

export default EditCompany;