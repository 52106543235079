import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, TablePaginationConfig } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { apiPartner, defaultAPIErrorHandler, ICompanyProtheus, IGetStoreProtheus } from '../../../../services/api';
import StoresTable from './table';
import BackButton from '../../../../components/back-button';

const Stores = () => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<IGetStoreProtheus[]>([]);
  const [pagination, setPagination] = React.useState({ page: 0, pageSize: 10 });
  const [paginationConfig, setPaginationConfig] = React.useState<TablePaginationConfig>({});

  // const [errorItems, setErrorItems] = React.useState<IStore[] | undefined>();
  // const [file, setFile] = React.useState<UploadFile | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const company = location.state as ICompanyProtheus;

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setPagination({
        ...pagination,
        page: page - 1,
      });
    },
    [pagination]
  );

  /**
  const handleFileUpload = React.useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      if (info.file.type !== 'text/csv' || info.fileList.length === 0) {
        setFile(null);
        return;
      }

      setFile(info.file);
    },
    []
  );

  const handleApiUpload = () => {
    var bodyFormData = new FormData();

    bodyFormData.append('file', file?.originFileObj as File);

    setLoading(true);
    api
      .post<IPaginatedList<IStore>>(
        `/companies/store/${company_id}`,
        bodyFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
        setErrorItems(response.data.errors);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setFile(null);
        setLoading(false);
      });
  };
  */

  React.useEffect(() => {
    setLoading(true);
    setItems([]);

    apiPartner
      .get<IGetStoreProtheus[]>(`/store/all`, {
        params: {
          companyId: company.codigo,
          companyStoreId: company.codigoLoja,
        }
      })
      .then((response) => {
        setPaginationConfig({
          current: 1,
          total: response.data.length,
          pageSize: 100,
          onChange: onChangePagination,
        });
        setItems(response.data);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [pagination, company, onChangePagination]);

  // JSX
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackButton
          title={`Administração > Empresas > ${company.nome} > Lojas`}
          pathToNavigate={`/admin/companies/${company.cnpj}/edit`}
          propsToNavigate={company}
          showConfirmation
        />
      </div>

      <Button
        style={{ marginTop: 20, marginBottom: 20 }}
        icon={<PlusOutlined />}
        onClick={() => navigate(`create`, { state: company })}
      >
        {'Nova Loja'}
      </Button>

      {/*
      <a href={PlanilhaModeloPDV} download={true}>
        <Button style={{ margin: 16 }} icon={<DownloadOutlined />}>
          Baixar CSV modelo
        </Button>
      </a>

      <Upload
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess?.('ok');
          }, 0);
        }}
        fileList={file ? [file] : []}
        multiple={false}
        onRemove={() => setFile(null)}
        onChange={handleFileUpload}
      >
        <Button icon={<UploadOutlined />}>Importar Arquivo de PDV(s)</Button>
      </Upload>

      <Button
        type="primary"
        onClick={handleApiUpload}
        disabled={file === null || loading}
        style={{ margin: 16 }}
      >
        {loading ? 'Enviando' : 'Enviar'}
      </Button>

      {errorItems && errorItems.length > 0 && <StoresError data={errorItems} />}
    */}

      <StoresTable
        paginationConfig={paginationConfig}
        data={items}
        companyData={company}
        loading={loading}
      />
    </div>
  );
};

export default Stores;
