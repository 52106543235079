import axios from 'axios';

export class AuthService {
  static AuthClient = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_URL,
  });

  private static token: {data: {access_token: string, refresh_token: string, expires_in: number}};

  static async getToken() {
    if (AuthService.token && Date.now() < AuthService.token.data.expires_in) return AuthService.token;
    AuthService.token = await this.AuthClient.get(`/auth/token?clientId=${process.env.REACT_APP_AUTH_API_CLIENT_ID}&clientSecret=${process.env.REACT_APP_AUTH_API_CLIENT_SECRET}`);
    AuthService.token.data.expires_in = Date.now() + 3600000;
    return AuthService.token;
  }
}
