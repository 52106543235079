import React from "react";
import { Alert, Button, ConfigProvider, Form, Input, Select, Upload, UploadFile, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

import BackButton from "../../../../../components/back-button";
import { ICategoryProtheus, ICompanyProtheus, IPaginatedListProtheus, apiPartner, defaultAPIErrorHandler } from "../../../../../services/api";
import { formatDateToBRT } from "../../../../../utils";
import { ICity, IState, apiIbge } from "../../../../../services/ibge";

const { TextArea } = Input;

const CreateStore = () => {
    const [loading, setLoading] = React.useState(false);

    const [states, setStates] = React.useState<IState[]>([])
    const [cities, setCities] = React.useState<ICity[]>([])
    const [selectedState, setSelectedState] = React.useState<{ value: number, label: string }>()
    const [selectedCity, setSelectedCity] = React.useState<{ value: string, label: string }>()

    const [categories, setCategories] = React.useState<ICategoryProtheus[]>([])
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>()

    const location = useLocation();
    const company = location.state as ICompanyProtheus;

    const getStates = async () => {
        apiIbge
            .get<IState[]>(`/states`)
            .then((response) => {
                setStates(response.data as IState[]);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));
    }

    const getCities = async (stateId: number) => {
        apiIbge
            .get<ICity[]>(`/cities`, { params: { stateId: stateId } })
            .then((response) => {
                setCities(response.data as ICity[]);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));

        return;
    };

    const citiesFilter = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toUpperCase().includes(input.toUpperCase());

    const getCategories = async () => {
        setLoading(true);
        await apiPartner
            .get<IPaginatedListProtheus<ICategoryProtheus>>(`/category`, {
                params: {
                    page: 0,
                    pageSize: 99999
                    // DEUS ME PERDOE
                }
            })
            .then((response) => {
                const categories = response.data.categories as ICategoryProtheus[];
                const activeCategories = categories.map((category) => category).filter(category => category.ativo === true);
                setCategories(activeCategories)
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));

        return;
    };

    const categoriesFilter = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleSubmit = React.useCallback(async (data: {
        companyName: string,
        tradingName: string,
        managerName: string,
        managerEmail: string,
        managerPhone: string,
        postalCode: string,
        street: string,
        streetNumber: string,
        streetComplement: string,
        neighbourhood: string,
        state: string,
        city: string,
        analist: string,
        discountPolicy: string,
        branch: string,
        unit: string,
    }) => {
        setLoading(true);

        if (!selectedState || !selectedCity) {
            notification.warning({
                message: "Aviso",
                description: "Informe o Estado e Cidade",
            });
            setLoading(false);

            return;
        }

        if (selectedCategories?.length === 0) {
            notification.warning({
                message: "Aviso",
                description: "Informe pelo menos uma Categoria",
            });
            setLoading(false);

            return;
        }

        try {
            await apiPartner.post('/store', {
                idEmpresa: company.codigo,
                logotipo: '',
                cnpj: company.cnpj,
                razaoSocial: data.companyName,
                nomeFantasia: data.tradingName,
                nomeGestor: data.managerName,
                telefoneGestor: data.managerPhone,
                emailGestor: data.managerEmail,
                cep: data.postalCode,
                logradouro: data.street,
                numero: data.streetNumber,
                complemento: data.streetComplement ?? "",
                bairro: data.neighbourhood,
                uf: selectedState.label,
                municipio: selectedCity.label,
                dataCriacaoSysducash: formatDateToBRT(new Date()),
                analista: data.analist,
                politicaDesconto: data.discountPolicy,
                filial: data.branch,
                unidade: data.unit,
                categorias: selectedCategories,
            });

            notification.success({
                message: "Loja criada com sucesso!",
            });
        } catch (error) {
            defaultAPIErrorHandler(error);
        } finally {
            setLoading(false);
            return;
        }
    }, [company, selectedState, selectedCity, selectedCategories])

    React.useEffect(() => {
        getStates()
        getCategories()
    }, [])

    React.useEffect(() => {
        if (selectedState) {
            getCities(selectedState.value);
        }
    }, [selectedState])

    return (
        <div>
            <BackButton
                title={`Administração > Empresas > ${company.nome} > Criar`}
                pathToNavigate={`..`}
                propsToNavigate={company}
                showConfirmation
            />
            <h1 style={{ marginTop: 24, marginBottom: 24 }}>{'Criar nova Loja para Empresa'}</h1>
            <Form
                name="form-store-create"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                initialValues={{
                    cnpj: company.cnpj
                }}
                layout="horizontal"
                labelAlign="left"
                onFinish={handleSubmit}
                style={{ maxWidth: 600, minWidth: 100, margin: 20 }}
            >
                <Form.Item label="Logo" name="logo">
                    <Alert
                        message="Para adicionar um logo, realize a edição dos dados após finalizar a criação"
                        description="(temporário)"
                        type="warning"
                        showIcon
                    />
                </Form.Item>
                <Form.Item label="CNPJ" name="cnpj">
                    <Input required />
                </Form.Item>
                <Form.Item label="Razão Social" name="companyName">
                    <Input required />
                </Form.Item>
                <Form.Item label="Nome Fantasia" name="tradingName">
                    <Input required />
                </Form.Item>
                <Form.Item label="Nome do Gestor" name="managerName">
                    <Input required />
                </Form.Item>
                <Form.Item label="Telefone do Gestor" name="managerPhone">
                    <Input required />
                </Form.Item>
                <Form.Item label="Email do Gestor" name="managerEmail">
                    <Input required />
                </Form.Item>
                <Form.Item label="Analista" name="analist">
                    <Input required />
                </Form.Item>
                <Form.Item label="Política de Desconto" name="discountPolicy">
                    <TextArea required rows={4} />
                </Form.Item>
                <Form.Item label="Filial" name="branch">
                    <Input required />
                </Form.Item>
                <Form.Item label="Unidade" name="unit">
                    <Input required />
                </Form.Item>
                <Form.Item label="Categorias" name="categories">
                    <ConfigProvider renderEmpty={() => <LoadingOutlined />}>
                        <Select
                            showSearch
                            allowClear
                            mode="multiple"
                            placeholder="Selecione ou pesquise uma categoria"
                            options={
                                categories.map((category) => {
                                    return {
                                        value: category.codigo,
                                        label: category.descricao,
                                    }
                                })
                            }
                            filterOption={categoriesFilter}
                            onChange={(value) => { setSelectedCategories(value) }}
                        />
                    </ConfigProvider>
                </Form.Item>

                <h3>Endereço</h3>
                <div className="address-container">

                    <Form.Item label="CEP" name="postalCode">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Logradouro" name="street">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Número" name="streetNumber">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Complemento" name="streetComplement">
                        <Input placeholder={'Opcional'} />
                    </Form.Item>
                    <Form.Item label="Bairro" name="neighbourhood">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Estado" name="state">
                        <Select
                            labelInValue
                            placeholder="Selecione um Estado"
                            options={
                                states.map((state) => {
                                    return {
                                        value: state.id,
                                        label: state.acronym,
                                    };
                                })
                            }
                            onChange={(value: { value: number, label: string }) => {
                                setSelectedState(value);
                                setSelectedCity(undefined);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Município" name="city">
                        <Select
                            showSearch
                            labelInValue
                            placeholder="Selecione ou pesquise uma cidade"
                            options={
                                cities.map((city) => {
                                    return {
                                        value: city.id,
                                        label: city.name.toUpperCase(),
                                    }
                                })
                            }
                            filterOption={citiesFilter}
                            onChange={(value) => setSelectedCity(value)}
                        />
                    </Form.Item>
                </div>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {loading ? <LoadingOutlined /> : "Salvar"}
                    </Button>
                </Form.Item>
            </Form>
            <br />
        </div>
    );
}

export default CreateStore;