import axios from "axios";
import { authApiInterceptor } from "../interceptors/authApi.interceptor";

export interface IState {
    id: number;
    name: string;
    acronym: string;
}

export interface ICity {
    id: string;
    name: string;
    stateId: number;
}

export const apiIbge = axios.create({
    baseURL: process.env.REACT_APP_API_IBGE_URL,
  });
apiIbge.interceptors.request.use((config) => authApiInterceptor(apiIbge, config));
  