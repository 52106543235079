import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, TablePaginationConfig } from 'antd';

import CompaniesTable from './table';
import CompaniesHeaderSearch from './headerSearch';
import { apiPartner, defaultAPIErrorHandler, ICompanyProtheus, IPaginatedListProtheus } from '../../../services/api';

const Companies = () => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<ICompanyProtheus[]>([]);
  const [pagination, setPagination] = React.useState({ page: 0, pageSize: 10 });
  const [paginationConfig, setPaginationConfig] = React.useState<TablePaginationConfig>({});
  const [search, setSearch] = React.useState({ cnpj: '', tradingName: '' });

  // const [errorItems, setErrorItems] = React.useState<ICompanyProtheus[] | undefined>();
  // const [file, setFile] = React.useState<UploadFile | null>(null);
  // const [showSegments, setShowSegments] = React.useState(false);

  const navigate = useNavigate();

  const onChangePagination = React.useCallback((page: number, _pageSize?: number) => {
    setPagination({
      ...pagination,
      page: page - 1,
    });
  },
    [pagination]
  );

  const onSearch = React.useCallback((searchFilters: { cnpj?: string, tradingName?: string }) => {
    setSearch({
      ...search,
      ...searchFilters,
    });
  },
    [search]
  );

  /**
  const handleFileUpload = React.useCallback((info: UploadChangeParam<UploadFile>) => {
    if (info.file.type !== 'text/csv' || info.fileList.length === 0) {
      setFile(null);
      return;
    }
    setFile(info.file);
  },
    []
  );

  const handleApiUpload = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file?.originFileObj as File);
    setLoading(true);
    api
      .post<IPaginatedList<ICompany>>(`/companies`, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
        setErrorItems(response.data.errors);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setFile(null);
        setLoading(false);
      });
  };
  */

  React.useEffect(() => {
    setLoading(true);
    setItems([]);

    const companies: ICompanyProtheus[] = [];

    const params = {
      ...pagination,
      tradingName: ''
    }

    if (search.tradingName && search.tradingName.length !== 0) {
      params.tradingName = search.tradingName
    }

    if (!search.cnpj || search.cnpj.length === 0) {
      apiPartner
        .get<IPaginatedListProtheus<ICompanyProtheus>>(`/company`, {
          params: params,
        })
        .then((response) => {
          setPaginationConfig({
            current: Number(response.data.page) + 1,
            total: response.data.total,
            pageSize: Number(response.data.pageSize),
            onChange: onChangePagination,
          });
          setItems(response.data.companies as ICompanyProtheus[]);
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => setLoading(false));

      return;
    }

    apiPartner
      .get<ICompanyProtheus>(`/company/${search.cnpj}`)
      .then((response) => {
        setPaginationConfig({
          current: 1,
          total: 1,
          pageSize: 1,
          onChange: onChangePagination,
        });
        companies.push(response.data)
        setItems(companies);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));

  },
    [pagination, search, onChangePagination]
  );

  // JSX
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 style={{ marginRight: 10 }}>{'Empresas Parceiras'}</h1>
      </div>
      <CompaniesHeaderSearch onSearch={onSearch} />

      <Button onClick={() => navigate(`create`)} style={{ marginTop: 20, marginBottom: 20 }} icon={<PlusOutlined />}>
        {'Nova Empresa'}
      </Button>

      {/* 
      <a href={PlanilhaModeloCompany} download={true}>
        <Button style={{ margin: 16 }} icon={<DownloadOutlined />}>
          {'Baixar CSV modelo'}
        </Button>
      </a> 

      <Button
        type={showSegments ? 'primary' : 'default'}
        onClick={() => setShowSegments(!showSegments)}
        style={{ margin: 16 }}
      >
        {'Segmentos'}
      </Button> 

      <Upload
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess?.('ok');
          }, 0);
        }}
        fileList={file ? [file] : []}
        multiple={false}
        onRemove={() => setFile(null)}
        onChange={handleFileUpload}
      >
        <Button style={{ margin: 16 }} icon={<UploadOutlined />}>
          {'Importar Arquivo de Empresa(s)'}
        </Button>
      </Upload> 

      <Button
        type="primary"
        onClick={handleApiUpload}
        disabled={file === null || loading}
        style={{ margin: 16 }}
      >
        {loading ? 'Enviando' : 'Enviar'}
      </Button> 

      {showSegments && <SegmentsList />}

      {errorItems && errorItems.length > 0 && (
        <CompaniesError data={errorItems} />
      )} 
      */}

      <CompaniesTable
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
      />
    </div>
  );
};

export default Companies;
