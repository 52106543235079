import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Space, Table, TablePaginationConfig } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import './index.css';
import { ICompanyProtheus, IGetStoreProtheus, IStoreProtheus } from '../../../../../services/api';
import { getFileUrlS3 } from '../../../../../services/aws';

const StoresTable = ({
  data,
  companyData,
  loading,
  paginationConfig,
}: {
  data: IGetStoreProtheus[];
  companyData: ICompanyProtheus,
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        title: 'Logo',
        render: (_value: unknown, record: IStoreProtheus, _index: number) => (
          record.logotipo ? <img src={getFileUrlS3(record.logotipo)} style={{ width: '64px', height: '64px', objectFit: "cover" }} /> : '' 
        ),
        key: 'logotipo'
      },
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
      },
      {
        title: 'Logradouro',
        dataIndex: 'logradouro',
        key: 'logradouro',
      },
      {
        title: 'Bairro',
        dataIndex: 'bairro',
        key: 'bairro',
      },
      {
        title: 'Número',
        dataIndex: 'numero',
        key: 'numero',
      },
      {
        title: 'Complemento',
        dataIndex: 'complemento',
        key: 'complemento',
      },
      {
        title: 'Cidade',
        dataIndex: 'municipio',
        key: 'municipio',
      },
      {
        title: 'Estado',
        dataIndex: 'uf',
        key: 'uf',
      },
      {
        title: 'Telefone',
        dataIndex: 'telefone',
        key: 'telefone',
      },
      {
        title: 'Editar',
        render: (_value: unknown, record: IGetStoreProtheus, _index: number) => (
          <Button onClick={() => navigate(`${record.codigo}/edit`, {
            state: { store: record, company: companyData }
          })
          }
            icon={<EditOutlined />}></Button>
        ),
        key: 'edit_store',
      },
    ],
    [companyData, navigate]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ ...paginationConfig, showSizeChanger: false }}
      columns={columns}
      className="table-stores"
      loading={loading}
      rowKey="codigo"
    />
  );
};

export default StoresTable;
