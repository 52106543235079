import { notification } from 'antd';
import axios from 'axios';
import { authApiInterceptor } from '../interceptors/authApi.interceptor';

export const eligibilityApi = axios.create({
  baseURL: process.env.REACT_APP_ELIGIBILITY_API_URL,
});
eligibilityApi.defaults.headers.common[
  'Authorization'
] = `Bearer ${process.env.REACT_APP_ELIGIBILITY_API_TOKEN}`;
eligibilityApi.interceptors.request.use((config) => authApiInterceptor(eligibilityApi, config));

export const defaultAPIErrorHandler = (error: any) => {
  notification.error({
    message: 'Erro',
    description: 'Ocorreu um erro inesperado',
  });
};
