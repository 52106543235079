import React from "react";
import { Button, Form, Input, InputNumber, Radio, Select, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./index.css";
import BackButton from "../../../../components/back-button";
import { apiPartner, defaultAPIErrorHandler } from "../../../../services/api";
import { ICity, IState, apiIbge } from "../../../../services/ibge";
import { formatDateToBRT } from "../../../../utils";

const { TextArea } = Input;

const CreateCompany = () => {
    const [loading, setLoading] = React.useState(false);

    const [spread, setSpread] = React.useState<string>();

    const [states, setStates] = React.useState<IState[]>([])
    const [cities, setCities] = React.useState<ICity[]>([])
    const [selectedState, setSelectedState] = React.useState<{ value: number, label: string }>()
    const [selectedCity, setSelectedCity] = React.useState<{ value: string, label: string }>()

    const citiesFilter = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toUpperCase().includes(input.toUpperCase());

    const getStates = async () => {
        apiIbge
            .get<IState[]>(`/states`)
            .then((response) => {
                setStates(response.data as IState[]);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));
    }

    const getCities = (stateId: number) => {
        apiIbge
            .get<ICity[]>(`/cities`, { params: { stateId: stateId } })
            .then((response) => {
                setCities(response.data as ICity[]);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));

        return;
    };

    // const [file, setFile] = React.useState<UploadFile | null>(null);

    // const handleUpload = React.useCallback((info: UploadChangeParam<UploadFile>) => {
    //     const allowedTypes = ["image/png", "image/jpeg"];

    //     if (info.fileList.length === 0) {
    //         setFile(null);
    //         return;
    //     }

    //     if (!allowedTypes.includes(info.file?.type || "")) {
    //         notification.error({
    //             message: "A imagem deve ser do tipo " + allowedTypes.join(" ou "),
    //         });
    //         setFile(null);
    //         return;
    //     }
    // },
    //     []
    // );

    const handleSubmit = React.useCallback(async (data: {
        cnpj: string,
        companyName: string,
        tradingName: string,
        managerName: string,
        managerPhone: string,
        managerEmail: string,
        postalCode: string,
        street: string,
        neighbourhood: string,
        streetNumber: string,
        streetComplement: string,
        analist: string,
        spreadPercent: string,
        discountPolicy: string
    }) => {
        setLoading(true);

        if (!selectedState || !selectedCity) {
            notification.warning({
                message: "Aviso",
                description: "Informe o Estado e Cidade",
            });
            setLoading(false);

            return;
        }

        if (!spread) {
            notification.warning({
                message: "Aviso",
                description: "Informe se há Spread",
            });
            setLoading(false);

            return;
        }

        try {
            await apiPartner.post('/company', {
                cnpj: data.cnpj,
                razaoSocial: data.companyName,
                nome: data.tradingName,
                nomeGestor: data.managerName,
                emailGestor: data.managerEmail,
                telefoneGestor: data.managerPhone,
                cep: data.postalCode,
                logradouro: data.street,
                bairro: data.neighbourhood,
                numero: data.streetNumber,
                complemento: data.streetComplement,
                uf: selectedState.label,
                municipio: selectedCity.label,
                codigoMunicipio: selectedCity.value.toString(),
                analista: data.analist,
                spread: spread,
                porcentagemSpread: data.spreadPercent.toString(),
                politicaDesconto: data.discountPolicy,
                dataCriacaoSysducash: formatDateToBRT(new Date()),
            });

            notification.success({
                message: "Empresa criada com sucesso!",
            });
        } catch (error) {
            defaultAPIErrorHandler(error);
        } finally {
            setLoading(false);
        }
    },
        [spread, selectedState, selectedCity]
    )

    React.useEffect(() => { getStates() }, [])

    React.useEffect(() => {
        if (selectedState) {
            getCities(selectedState.value);
        }
    }, [selectedState])

    return (
        <div>
            <BackButton
                title="Administração > Empresas > Criar"
                pathToNavigate="/admin/companies"
                showConfirmation
            />
            <h1 style={{ marginTop: 24, marginBottom: 24 }}>{'Criar nova Empresa Parceira'}</h1>
            <Form
                name="form-company-create"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"
                labelAlign="left"
                initialValues={{ spreadPercent: '0' }}
                onFinish={handleSubmit}
                style={{ maxWidth: 600, minWidth: 100, margin: 20 }}
            >
                {/* <Form.Item label="Logo" name="file_info">
                    <Upload
                        customRequest={({ onSuccess }) => {
                            setTimeout(() => {
                                onSuccess?.("ok");
                            }, 0);
                        }}
                        fileList={file ? [file] : []}
                        listType="picture"
                        multiple={false}
                        accept="image/png,image/jpeg"
                        onPreview={() => { }}
                        onChange={handleUpload}
                    >
                        {file ? null : <Button icon={<UploadOutlined />}>Carregar</Button>}
                    </Upload>
                </Form.Item> */}
                <Form.Item label="CNPJ" name="cnpj">
                    <Input required />
                </Form.Item>
                <Form.Item label="Razão Social" name="companyName">
                    <Input required />
                </Form.Item>
                <Form.Item label="Nome Fantasia" name="tradingName">
                    <Input required />
                </Form.Item>
                <Form.Item label="Nome do Gestor" name="managerName">
                    <Input required />
                </Form.Item>
                <Form.Item label="Telefone do Gestor" name="managerPhone">
                    <Input required />
                </Form.Item>
                <Form.Item label="Email do Gestor" name="managerEmail">
                    <Input required />
                </Form.Item>
                <Form.Item label="Analista" name="analist">
                    <Input required />
                </Form.Item>
                <Form.Item label="Spread" name="spread">
                    <Radio.Group onChange={(value) => setSpread(value.target.value)}>
                        <Radio value={'Sim'}>Sim</Radio>
                        <Radio value={'Nao'}>Não</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Porcentagem de Spread" name="spreadPercent">
                    <InputNumber
                        type={'number'}
                        min={0}
                        max={100}
                        pattern={'[1-9]'}
                        addonAfter={'%'}
                    />
                </Form.Item>
                <Form.Item label="Política de Desconto" name="discountPolicy">
                    <TextArea required rows={4} />
                </Form.Item>

                <h3>Endereço</h3>
                <div className="address-container">

                    <Form.Item label="CEP" name="postalCode">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Logradouro" name="street">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Número" name="streetNumber">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Complemento" name="streetComplement">
                        <Input placeholder={'Opcional'} />
                    </Form.Item>
                    <Form.Item label="Bairro" name="neighbourhood">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Estado" name="state">
                        <Select
                            labelInValue
                            placeholder="Selecione um Estado"
                            options={
                                states.map((state) => {
                                    return {
                                        value: state.id,
                                        label: state.acronym,
                                    };
                                })
                            }
                            onChange={(value: { value: number, label: string }) => {
                                setSelectedState(value);
                                setSelectedCity(undefined);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Município" name="city">
                        <Select
                            showSearch
                            labelInValue
                            placeholder="Selecione ou pesquise uma cidade"
                            options={
                                cities.map((city) => {
                                    return {
                                        value: city.id,
                                        label: city.name.toUpperCase(),
                                    }
                                })
                            }
                            filterOption={citiesFilter}
                            onChange={(value) => setSelectedCity(value)}
                        />
                    </Form.Item>
                </div>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {loading ? <LoadingOutlined /> : "Salvar"}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default CreateCompany;