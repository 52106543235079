export const brazilianStates = [
  "RO",
  "AC",
  "AM",
  "RR",
  "PA",
  "AP",
  "TO",
  "MA",
  "PI",
  "CE",
  "RN",
  "PB",
  "PE",
  "AL",
  "SE",
  "BA",
  "MG",
  "ES",
  "RJ",
  "SP",
  "PR",
  "SC",
  "RS",
  "MS",
  "MT",
  "GO",
  "DF",
];

export const formatCNPJ = (value: number) => {
  if (!value) return 0;
  return `${value}`.replace("(^\d{1}.\d{2}.\d{3}/\d{4}-\d{2}$)", "$1.$2.$3-$4");
}

export const formatDateToBRT = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const brtDate = date.toLocaleString('pt-BR', options);
  const [day, month, year] = brtDate.split('/');
  
  return `${year}-${month}-${day}`;
}
