import React from "react";
import { useLocation } from "react-router-dom";
import { Button, ConfigProvider, Form, Input, Select, Upload, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { ICategoryProtheus, ICompanyProtheus, IGetStoreProtheus, IPaginatedListProtheus, apiPartner, defaultAPIErrorHandler } from "../../../../../services/api";
import { ICity, IState, apiIbge } from "../../../../../services/ibge";
import BackButton from "../../../../../components/back-button";
import { getFileS3, getFileUrlS3, uploadFileToS3 } from "../../../../../services/aws";

const { TextArea } = Input;

const EditStore = () => {
    const [loading, setLoading] = React.useState(false);

    const [logo, setLogo] = React.useState('');
    const [logoUrl, setLogoUrl] = React.useState('');

    const [states, setStates] = React.useState<IState[]>([])
    const [cities, setCities] = React.useState<ICity[]>([])
    const [selectedState, setSelectedState] = React.useState<{ value: number, label: string }>()
    const [selectedCity, setSelectedCity] = React.useState<{ value: string, label: string }>()

    const [categories, setCategories] = React.useState<ICategoryProtheus[]>([])
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>()

    const [form] = Form.useForm()

    const location = useLocation();
    const store = location.state.store as IGetStoreProtheus;
    const company = location.state.company as ICompanyProtheus;

    const getStates = async () => {
        apiIbge
            .get<IState[]>(`/states`)
            .then((response) => {
                setStates(response.data as IState[]);
                const selectState = response.data.map((state) => {
                    return {
                        value: state.id,
                        label: state.acronym,
                    }
                }).find(state => state.label === store.uf);
                setSelectedState(selectState);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));
    }

    const getCities = async (stateId: number) => {
        apiIbge
            .get<ICity[]>(`/cities`, { params: { stateId: stateId } })
            .then((response) => {
                setCities(response.data as ICity[]);
                const selectCity = response.data.map((city) => {
                    return {
                        value: city.id,
                        label: city.name.toUpperCase(),
                    }
                }).find(city => city.label === store.municipio?.toUpperCase());
                setSelectedCity(selectCity);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));

        return;
    };

    const citiesFilter = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toUpperCase().includes(input.toUpperCase());

    const getCategories = async () => {
        await apiPartner
            .get<IPaginatedListProtheus<ICategoryProtheus>>(`/category`, {
                params: {
                    page: 0,
                    pageSize: 99999
                    // DEUS ME PERDOE
                }
            })
            .then((response) => {
                const categories = response.data.categories as ICategoryProtheus[];
                const activeCategories = categories.map((category) => category).filter(category => category.ativo === true);
                setCategories(activeCategories);
                setSelectedCategories(store.codigosTaxonomias);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));

        return;
    };

    const categoriesFilter = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const getLogo = (fileName: string) => {
        setLogo(fileName)
        const url = getFileUrlS3(fileName);
        setLogoUrl(url);
    }

    const beforeLogoUpload = async (file: File) => {
        const isValid = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isValid) {
            notification.info({
                message: "Apenas imagens em JPEG ou PNG.",
            });

            return false;
        }

        if (store.codigo) {
            const fileName = await uploadFileToS3(store.codigo, file);
            setLogo(fileName)
            const url = getFileUrlS3(fileName);
            setLogoUrl(url);
        }

        return false;
    };

    const handleSubmit = React.useCallback(async (data: {
        cnpj: string,
        name: string,
        managerPhone: string,
        managerEmail: string,
        postalCode: string,
        street: string,
        streetNumber: string,
        streetComplement: string,
        neighbourhood: string,
        analist: string,
        discountPolicy: string,
        branch: string,
        unit: string,
    }) => {
        setLoading(true);

        if (!selectedState || !selectedCity) {
            notification.warning({
                message: "Aviso",
                description: "Informe o Estado e Cidade",
            });
            setLoading(false);

            return;
        }

        if (selectedCategories?.length === 0) {
            notification.warning({
                message: "Aviso",
                description: "Informe pelo menos uma Categoria",
            });
            setLoading(false);

            return;
        }

        try {
            await apiPartner.put(`/store`, {
                logotipo: logo,
                codigo: store.codigo,
                cnpj: data.cnpj,
                nomeFantasia: data.name,
                telefoneGestor: data.managerPhone,
                emailGestor: data.managerEmail,
                cep: data.postalCode,
                logradouro: data.street,
                numero: data.streetNumber,
                complemento: data.streetComplement,
                bairro: data.neighbourhood,
                uf: selectedState.label,
                municipio: selectedCity.label,
                analista: data.analist,
                politicaDesconto: data.discountPolicy,
                filial: data.branch,
                unidade: data.unit,
                categorias: selectedCategories,
            });

            notification.success({
                message: "Loja editada com sucesso!",
            });
        } catch (error) {
            defaultAPIErrorHandler(error);
        } finally {
            setLoading(false);
        }
    }, [store, logo, selectedState, selectedCity, selectedCategories]);


    React.useEffect(() => {
        if (!store) {
            notification.warning({
                message: 'Aviso',
                description: 'Loja não encontrada',
            });

            return;
        }

        getStates();
        getCategories();
        getLogo(store.logotipo ?? '');
    }, [])

    React.useEffect(() => {
        if (selectedState) {
            form.setFieldValue('state', selectedState)
            getCities(selectedState.value);
        }
    }, [form, selectedState])

    React.useEffect(() => {
        form.setFieldValue('city', selectedCity)
    }, [form, selectedCity])

    return (
        <div>
            <BackButton
                title={`Administração > Empresas > ${company.nome} > Lojas > ${store.codigo} > Editar`}
                pathToNavigate={`../..`}
                propsToNavigate={company}
                showConfirmation
            />
            <h1 style={{ marginTop: 24, marginBottom: 24 }}>{'Editar Loja'}</h1>
            <Form
                form={form}
                name="form-store-edit"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                initialValues={{
                    cnpj: store.cnpj,
                    name: store.nome,
                    managerPhone: store.telefone,
                    managerEmail: store.email,
                    postalCode: store.cep,
                    street: store.logradouro,
                    neighbourhood: store.bairro,
                    streetNumber: store.numero,
                    streetComplement: store.complemento,
                    analist: store.analista,
                    discountPolicy: store.politicaDesconto,
                    branch: store.filial,
                    unit: store.unidade,
                }}
                layout="horizontal"
                labelAlign="left"
                onFinish={handleSubmit}
                style={{ maxWidth: 600, minWidth: 100, margin: 20 }}
            >
                <Form.Item label="Logo" name="logo">
                    <Upload
                        name="logoImage"
                        listType="picture-card"
                        multiple={false}
                        showUploadList={false}
                        beforeUpload={beforeLogoUpload}
                    >
                        {logoUrl ? <img src={logoUrl} style={{ width: '100%', height: '100%', objectFit: "cover" }} /> : <PlusOutlined />}
                    </Upload>
                </Form.Item>
                <Form.Item label="CNPJ" name="cnpj">
                    <Input required />
                </Form.Item>
                <Form.Item label="Nome Fantasia" name="name">
                    <Input required />
                </Form.Item>
                <Form.Item label="Telefone do Gestor" name="managerPhone">
                    <Input required />
                </Form.Item>
                <Form.Item label="Email do Gestor" name="managerEmail">
                    <Input required />
                </Form.Item>
                <Form.Item label="Analista" name="analist">
                    <Input required />
                </Form.Item>
                <Form.Item label="Política de Desconto" name="discountPolicy">
                    <TextArea required rows={4} />
                </Form.Item>
                <Form.Item label="Filial" name="branch">
                    <Input required />
                </Form.Item>
                <Form.Item label="Unidade" name="unit">
                    <Input required />
                </Form.Item>
                <Form.Item label="Categorias" name="categories">
                    <ConfigProvider renderEmpty={() => <LoadingOutlined />}>
                        <Select
                            showSearch
                            allowClear
                            mode="multiple"
                            placeholder="Selecione ou pesquise uma categoria"
                            value={selectedCategories}
                            options={
                                categories.map((category) => {
                                    return {
                                        value: category.codigo,
                                        label: category.descricao,
                                    }
                                })
                            }
                            filterOption={categoriesFilter}
                            onChange={(value) => { setSelectedCategories(value) }}
                        />
                    </ConfigProvider>
                </Form.Item>

                <h3>Endereço</h3>
                <div className="address-container">
                    <Form.Item label="CEP" name="postalCode">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Logradouro" name="street">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Número" name="streetNumber">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Complemento" name="streetComplement">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Bairro" name="neighbourhood">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Estado" name="state">
                        <Select
                            labelInValue
                            placeholder="Selecione um Estado"
                            options={
                                states.map((state) => {
                                    return {
                                        value: state.id,
                                        label: state.acronym,
                                    };
                                })
                            }
                            onChange={(value: { value: number, label: string }) => {
                                setSelectedState(value);
                                setSelectedCity(undefined);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Município" name="city">
                        <Select
                            showSearch
                            labelInValue
                            placeholder="Selecione ou pesquise uma cidade"
                            options={
                                cities.map((city) => {
                                    return {
                                        value: city.id,
                                        label: city.name.toUpperCase(),
                                    }
                                })
                            }
                            filterOption={citiesFilter}
                            onChange={(value) => setSelectedCity(value)}
                        />
                    </Form.Item>
                </div>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {loading ? <LoadingOutlined /> : "Salvar"}
                    </Button>
                </Form.Item>
            </Form>
            <br />
        </div >
    );
}

export default EditStore;