import { DeleteObjectCommand, GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
  }
});

export const getFileS3 = async (fileName: string) => {
  try {
    const getCommand = new GetObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3_LOGO_BUCKET_NAME,
      Key: fileName
    });

    const logo = await client.send(getCommand);

    return logo;
  } catch (error) {
    throw new Error(`Erro ao tentar salvar a imagem: ${error}`);
  }
}

export const getFileUrlS3 = (fileName: string) => {
  return `https://${process.env.REACT_APP_AWS_S3_LOGO_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${fileName}`
}

export const uploadFileToS3 = async (id: string, file: File) => {
  try {
    const fileType = file.type?.split('/').pop();
    const fileName = `${id}-logo.${fileType}`;

    const putCommand = new PutObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3_LOGO_BUCKET_NAME,
      Key: fileName,
      Body: file
    });

    await client.send(putCommand);

    return fileName;
  } catch (error) {
    throw new Error(`Erro ao tentar salvar a imagem: ${error}`);
  }
}

export const deleteFileS3 = async (id: string) => {
  try {
    const deleteCommand = new DeleteObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3_LOGO_BUCKET_NAME,
      Key: `${id}-logo`
    });

    const logo = await client.send(deleteCommand);

    return logo;
  } catch (error) {
    throw new Error(`Erro ao tentar salvar a imagem: ${error}`);
  }
}