import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Space, Table, TablePaginationConfig } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import './index.css';
import { ICompanyProtheus } from '../../../../services/api';

const CompaniesTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: ICompanyProtheus[];
  loading?: boolean;
  paginationConfig?: TablePaginationConfig;
}) => {
  const navigate = useNavigate();

  const columns = React.useMemo(() => [
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
    },
    {
      title: 'Razão Social',
      dataIndex: 'razaoSocial',
      key: 'razaoSocial',
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Editar',
      render: (_value: unknown, record: ICompanyProtheus, _index: number) => (
        <Button
          onClick={() => navigate(`/admin/companies/${record.cnpj}/edit`, {
            state: record
          })
          }
          icon={<EditOutlined />} />
      ),
      key: 'edit_company',
    },
    {
      title: 'Ações',
      render: (_value: unknown, record: ICompanyProtheus, _index: number) => (
        <Space size="large">
          <Button
            onClick={() => navigate(`/admin/companies/${record.cnpj}/stores`, {
              state: record
            })}
          >
            {'Lojas'}
          </Button>
          <Space>
            {record.ativo ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} label='Inativo'  />}
            {record.ativo ? 'Ativo' : 'Inativo'}
          </Space>
        </Space>
      ),
      key: 'actions',
    },
  ], [navigate]);

  return (
    <Table
      dataSource={data}
      pagination={{ ...paginationConfig, showSizeChanger: false }}
      columns={columns}
      className="table-companies"
      loading={loading}
      rowKey="cnpj"
    />
  );
};

export default CompaniesTable;
