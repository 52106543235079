import React from 'react';
import { Button, Form, FormInstance, Input } from 'antd';

const CompaniesHeaderSearch = ({ onSearch }: { onSearch: (searchFilters: any) => void; }) => {
  const formRef = React.useRef<FormInstance>(null);

  const handleSubmit = React.useCallback(
    async (data: { cnpj: string, tradingName: string }) => {
      onSearch(data);
    },
    [onSearch]
  );

  return (
    <Form
      name="control-ref"
      layout="inline"
      onFinish={handleSubmit}
      style={{ margin: '1%' }}
      ref={formRef}
    >
      <Form.Item
        label="CNPJ"
        name="cnpj"
        rules={[
          {
            len: 14,
            required: false,
          },
        ]}
      >
        <Input placeholder="Opcional" />
      </Form.Item>
      <Form.Item
        label="Nome Fantasia"
        name="tradingName"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input placeholder="Opcional" />
      </Form.Item>

      <br />
      <Form.Item wrapperCol={{ offset: 2 }}>
        <Button type="primary" htmlType="submit">
          Buscar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompaniesHeaderSearch;
